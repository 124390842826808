.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  padding-bottom: 170px;
}


/* loading page */
.propagate, .loading  {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  padding-bottom: 170px;
  
}


.text, .propagate, .loading  {
    font-family: "Lobster-Regular";
}

/*menu component */
.menuLogo {
  height: 30px;
  padding-left: 10px;
  padding-bottom: 3px;
}
.navbar-brand{
  margin-left: 0;
  margin-right: 1rem;
}

/* About component */
.about {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  padding-bottom: 70px;
}
.link {
  color: black;
  padding-top: 5px;
  padding-right: .5rem;
  padding-left: .5rem;
  text-decoration: none;
  
}
/* footer */
.footer {
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  padding: 50px;

}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
}


/* footer left*/

.footer .footer-left {
  width: 33%;
}

.footer .aboutFooter {
  line-height: 20px;
  color: black;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
  padding-left: 20px;
}

.footer .aboutFooter span {
  display: block;
  color: black;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer .icons {
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 20px; 

}
/* .icons {
  padding-left: 5px;
} */

.footer .icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  font-size: 20px;
  color: black;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
}


/* footer center*/

.footer .footer-center {
  width: 30%;
}

.footer .footer-center i {
  background-color: black;
  color: black;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer .footer-center p {
  display: inline-block;
  color: black;
  vertical-align: middle;
  margin: 0;
}

.footer .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer .footer-center p a {
  color: #0099ff;
  text-decoration: none;
}


/* footer right*/

.footer .footer-right {
  width: 35%;
}

.footer h2 {
  color: black;
  font-size: 36px;
  font-weight: normal;
  margin: 0;
}

.footer h2 span {
  color: #0099ff;
}

.footer .menu {
  color: black;
  margin: 20px 0 12px;
  padding: 0;
}

.footer .menu a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer .menu a:hover {
  color: #0099ff;
}

.footer .name {
  color: #0099ff;
  font-size: 20px;
  font-weight: normal;
  margin: 0;
  padding-top: 20px;
}

@media (max-width: 767px) {
  .footer {
    font-size: 14px;
  }
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: block;
    width: 100%;
    text-align: center;
  }
  .footer .footer-center i {
    margin-left: 0;
  }
}
.facebook, .sms  {
  padding-left: 3px;
}


/* footer hover */
@-webkit-keyframes bounce {
  0%,  
  100% {
     -webkit-transform: translateY(0);
  } 
  50% {
      -webkit-transform: translateY(-10px);
  }
}
@-moz-keyframes bounce {
  0%, 
  100% {
     -moz-transform: translateY(0);
  }
  50% {
     -moz-transform: translateY(-10px);
  }
}
@-o-keyframes bounce {
  0%,
  100% {
     -o-transform: translateY(0);
  }
  50% {
     -o-transform: translateY(-10px);
  }
}
@keyframes bounce {
  0%,  
  100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}


.facebook:hover, .gmail:hover, .telephone:hover, .sms:hover, .location:hover {
  cursor: pointer;
  transform: translateY(-10px);
}
.phoneLink:hover, .mailLink:hover {
  color: blue;
 
}
.phoneLink, .mailLink {
  color: black;
}
/* icons component */
.iconSocial {
  display: flex;
  flex-direction: row;
  justify-content: center;

  
}
.icon {
  background-color: #c9d2d8 ;
border-radius: 2px;
  font-size: 20px;
  color: black;
  width: 35px;
  height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
  text-align: center;
}
.iconsConteiner {
  min-height:80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
.logoFooter{
  width:50px;
  height: 70px;
  padding-top: 10px;
}